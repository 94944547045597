import fetch from '../../../apis/request'

export default class Apis {
  // 分页查询所有吊篮
  static findAllDl(formData) {
    return fetch.get('/pc/hangingBasket/findAll', {
      params: formData
    })
  }
  // 添加吊篮
  static addDl(formData) {
    return fetch.post('/pc/hangingBasket/add', formData)
  }
  // 提交吊篮
  static submitDl(formData) {
    return fetch.post('/pc/hangingBasket/submit', formData)
  }
  // 编辑吊篮
  static updateDl(formData) {
    return fetch.post('/pc/hangingBasket/update', formData)
  }
  // 编辑一台一册
  static updateYtTc(formData) {
    return fetch.post('/pc/hangingBasket/changeFileInfo', formData)
  }
  // 删除吊篮
  static delDl(data) {
    return fetch.post(`/pc/hangingBasket/del?id=${data}`)
  }
  // 拆除吊篮
  static removeDl(data) {
    return fetch.post(`/pc/hangingBasket/remove?id=${data}`)
  }
  // 查询吊篮详情
  static findByBasketId(data) {
    return fetch.get(`/pc/hangingBasket/findById`,{
      params: data
    })
  }
  // 修改 启用/报停 状态
  static changeDoStatus(data) {
    return fetch.post(`/pc/hangingBasket/createDoStatus`,data)
  }

  // 审批 启用/报停 状态
  static confirmDoStatus(id) {
    return fetch.post(`/pc/hangingBasket/confirm?id=${id}`)
  }

  // 审批 打回 状态
  static confirmBack(id) {
    return fetch.post(`/pc/hangingBasket/back?id=${id}`)
  }

  // 查询 启用/报停 记录
  static getDoStatusRecord(data) {
    return fetch.get(`/pc/do-record/page`,{
      params: data
    })
  }

  // 修改检查通知人
  static changeCheckNotice(form) {
    return fetch.post('/pc/hangingBasket/changeCheckNotice', form)
  }
  // 查找属于角色总工程师和安全总监的用户
  static findCheckPerson(formData) {
    return fetch.get(`/pc/organization/findByRoleId`,{
      params: formData
    })
  }
  // 根据组织机构code和角色id获得所需要的的用户
  static findByDepartmentId(code) {
    if (typeof(code)=='string'){
      return fetch.get(`/pc/organization/findByDepartmentId?departmentCode=${code}`)
    }else{
      return fetch.get(`/pc/organization/findByDepartmentId`,{
        params: code
      })
    }
  }
  // 上传文件
  static uploadFile(form) {
    return fetch.post(`/admin/public/uploadFile`,form)
  }

  // 查询机械租赁商all
  static machineLessorListAll(formData) {
    return fetch.get('/pc/machine-lessor/findAll', {
      params: formData
    })
  }

  // 查询机械租赁商分页
  static machineLessorListPage(formData) {
    return fetch.get('/pc/machine-lessor/page', {
      params: formData
    })
  }

  // 添加机械租赁商
  static addMLL(formData) {
    return fetch.post('/pc/machine-lessor/add', formData)
  }

  // 编辑机械租赁商
  static updateMLL(formData) {
    return fetch.post('/pc/machine-lessor/update', formData)
  }

  // 删除机械租赁商
  static delMLL(data) {
    return fetch.post(`/pc/machine-lessor/del?id=${data}`)
  }


  // 查询大型机械分页
  static largeMachinePage(formData) {
    return fetch.get('/pc/large-machine/page', {
      params: formData
    })
  }

  // 添加大型机械
  static addLM(formData) {
    return fetch.post('/pc/large-machine/add', formData)
  }

  // 提交添加大型机械
  static subAddLM(formData) {
    return fetch.post('/pc/large-machine/submitAdd', formData)
  }

  // 编辑大型机械
  static updateLM(formData) {
    return fetch.post('/pc/large-machine/update', formData)
  }

  // 提交编辑大型机械
  static subUpdateLM(formData) {
    return fetch.post('/pc/large-machine/submitUpdate', formData)
  }

  // 撤场大型机械
  static delLM(data) {
    return fetch.post(`/pc/large-machine/withdrawal?id=${data}`)
  }

  // 查询大型机械详情
  static largeMachineId(data) {
    return fetch.get(`/pc/large-machine/findById`,{
      params: data
    })
  }
}
