<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-button type="primary" size="small" @click="add">新增机械租赁商</el-button>
        <el-button type="primary" size="mini" @click="edit()">修改机械租赁商</el-button>
        <el-button type="danger" size="mini" @click="del()">删除</el-button>
      </div>
      <div class="search">
        <el-input v-model="SearchValue.name" placeholder="请输入机械租赁商名称" size="small" style="width:200px;margin-right:15px;" clearable></el-input>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable1" :data="tableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}" @selection-change="selectItem">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column align="center" type="index" label="序号" ></el-table-column>
        <el-table-column align="center" prop="name" label="机械租赁商名称"></el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
        <el-table-column align="center" prop="updateTime" label="更新时间"></el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top:10px;">
      <el-pagination
          v-if="dataTotal > 10"
          background
          :current-page.sync="SearchValue.pageCurrent"
          :page-size="SearchValue.pageSize"
          :total="dataTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>

    <!-- 详情 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" @closed="close()">
      <el-form ref="formData" :model="formData" class="demo-form-inline" label-width="140px" :rules="rules" style="margin-top: 20px;">
        <el-form-item label="机械租赁商名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入机械租赁商名称" maxlength="20" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" size="small">确 定</el-button>
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import apis from '../api/nacelle-set.js'

export default {
  name: "three",

  components: {},

  props: {},

  data() {
    return {
      dataTotal: 0,
      tableData: [],
      multipleSelection: [],
      SearchValue: {
        name: '',
        pageCurrent: 1,
        pageSize: 10
      },
      formData: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入租赁商名称', trigger: 'blur' }
        ],
      },
      dialogVisible: false,
      title: ''
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem'])
  },

  watch: {
    companyId() {
      // this.init()
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    // 选中
    selectItem(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable1.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable1.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection = newRows;
      } else {
        this.multipleSelection = rows;
      }
    },
    async init() {
      // if (!this.companyId) return this.init.async
      const data = await apis.machineLessorListPage(this.SearchValue)
      this.tableData = data.list
      this.dataTotal = data.total
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.SearchValue.pageCurrent = val
      this.init().async
    },
    search() {
      this.SearchValue.pageCurrent = 1
      this.init().async
    },
    add() {
      this.title = '新增'
      this.dialogVisible = true
    },
    edit() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一条数据')
      const data = this.multipleSelection[0]
      this.title = '修改'
      this.formData = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
    },
    del() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一条数据')
      const data = this.multipleSelection[0]
      this.$confirm('是否删除该机械租赁商', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.delMLL(data.id)
        this.$message.success('操作成功')
        this.init().async
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    close() {
      this.formData = {name: ''}
      this.$refs.formData.resetFields()
    },
    confirm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.title === '新增') {
            apis.addMLL(this.formData).then(res => {
              console.log(res)
              this.$message.success('操作成功')
              this.init().async
              this.dialogVisible = false
            })
          } else {
            apis.updateMLL(this.formData).then(res => {
              console.log(res)
              this.$message.success('操作成功')
              this.init().async
              this.dialogVisible = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.content{
  height: 100%;
  padding: 10px 20px;
  .operation {
    padding: 0 0 10px 0;
  }
}
</style>
