import BaseURL from './baseurl'
import axios from 'axios'
import { Message } from 'element-ui'
import { accessToken } from "../utils/local-cache"
import router from "../router"
// import qs from "qs"
// 超时时间
axios.defaults.timeout = 50000
// axios.defaults.withCredentials = true
// http请求拦截器
// var loadinginstace
axios.interceptors.request.use(config => {
  config.baseURL = BaseURL
  // 添加token等全局参数
  config.headers["token"] = 'pcToken'
  let token
  try {
    token = accessToken.get()
  }catch (e) {
    token = ''
  }
  if (token!==''&&token!==undefined) {
    // if (!config.body) config.body = {}
    // config.body.token = token
    config.headers["pcToken"] = token
    // config.headers["Authorization"] = token
  }
  // if (config.method === 'post') {
  //   config.data = qs.stringify(config.data)
  // }
  // loadinginstace = Loading.service({ fullscrenn: true })
  return config
}, error => {
  // loadinginstace.close()
  Message.error({
    message: '加载超时'
  })
  return Promise.reject(error)
})
// http响应拦截器
axios.interceptors.response.use(data => { // 响应成功关闭loading
  // loadinginstace.close()
  if (data.data.statusCode !== 200) {
    Message.error({
      message: data.data.message,
      duration: 5000
    })
    if (data.data.statusCode === 301) {
      accessToken.set('')
      router.replace({ name: 'Login' })
    }
    if (data.data.statusCode === 300) {
      // loadinginstace.close()
      // Message.error({
      //   message: data.data.message,
      //   duration: 5000
      // })
      return Promise.reject(data.data.message)
    }
  }
  return data.data.data
}, error => {
  // loadinginstace.close()
  // Message.error({
  //   message: error,
  //   duration: 5000
  // })
  return Promise.reject(error)
})

export default axios
